import React from 'react'
import { Link } from 'gatsby'

const HeaderGeneric = (props) => (
    <Link to="/">
    <header id="header">
        <h1>{props.text}</h1>
        <p>{props.subtext}</p>
    </header>
    </Link>
)

export default HeaderGeneric
